.upload-license-certification-input {
    .form-control {
        height: auto;
    }

    input[type=file]::file-selector-button {
        display: hidden;
        margin-right: 20px;
        border: none;
        background: #010a63;
        padding: 10px 20px;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
        transition: #010a63 .2s ease-in-out;
    }

    input[type=file]::file-selector-button:hover {
        background: #4056ce;
    }

    .license-upload-style {
        width: 100%;
        display: grid;
        place-items: center;
        grid-template-columns: 2fr 2fr;
        gap: 5px;
        margin-top: 20px;

        .preview-files-style {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-height: 90px;
            background-color: rgb(247, 247, 247);
            border: 1px solid rgb(215, 215, 215);
            border-radius: 5px;
        }
    }
}

@media (max-width: 1702px) {
    .license-upload-style {
        grid-template-columns: 1fr;
    }
}