.team-members-wrapper {
    .time-clock-team {
        .select-team-checkbox {
            &::after {
                border-radius: 0.25rem;
                border-color: #5e72e4;
                background-color: #5e72e4;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%274%27 viewBox=%270 0 4 4%27%3e%3cpath stroke=%27%23fff%27 d=%27M0 2h4%27/%3e%3c/svg%3e");
            }
        }
    }

    .dropdown-menu {
        &.show {
            top: 40px !important;
            left: -160px !important;
        }
    }
}

.clock-in-info-tooltip {
    .tooltip {
        &.show {
            opacity: 1;
        }

        .tooltip-inner {
            min-width: 300px;
            max-width: none;
            background-color: white;
            padding: 1rem 1.5rem;
            box-shadow: 0px 15px 35px #32325D33;
            color: #32325d;

            $status: ("on-break":#F99B16, "clocked-in": #5428e0, "clocked-out":red );

            @each $name, $value in $status {
                .text-#{$name}{
                    color: $value;
                    font-weight: bolder;
                }
            }

        }
    }

    .info-tooltip-dashed-border {
        border: 1px dashed rgb(232, 232, 232);
        border-radius: 5px;
        padding: 15px;
    }
    
}