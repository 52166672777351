.disclosure-button-style {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: rgb(225, 225, 225);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 15px;
}

.disclosure-panel-style {
    padding: 10px 16px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(242, 242, 242);
}